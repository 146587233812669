import {configureStore} from "@reduxjs/toolkit";
import thunk from "redux-thunk";
import {BasketSlice} from "./slices/BasketSlice";
import {ProductInfoSlice} from "./slices/ProductInfoSlice";

const middleWare = [thunk];

export const Store = configureStore({
    reducer: { basket: BasketSlice.reducer, products: ProductInfoSlice.reducer},
    middleWare,
    });
