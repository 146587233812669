import {Link} from "react-router-dom";

export default function Logo() {
    return (
        <div style={{marginRight: 5, marginLeft: 5}}>
            <Link to="/" style={{color: "#555", textDecoration: "none"}}>
                <h1 style={{textAlign: "center"}}>PuriFi<br/>Reusables</h1>
            </Link>
        </div>
    );
}
