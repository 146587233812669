import React, {useEffect, useRef, useState} from "react";
import config from "../config/config";
import convertBasketItemsToPaypalPurchaseUnits from "../utils/PaypalUtil";
import {connect, useDispatch} from "react-redux";
import {clearBasket} from "../store/slices/BasketSlice";
import {useNavigate} from "react-router-dom";

function ReactPayPal({basketItems}) {
    const [paid, setPaid] = useState(false);
    const [error] = useState(null);
    const paypalRef = useRef();
    const dispatch = useDispatch();
    const navigate = useNavigate();

    // To show PayPal buttons once the component loads
    useEffect(() => {
        window.paypal
            .Buttons({
                createOrder: (data, actions) => {
                    const order = convertBasketItemsToPaypalPurchaseUnits(basketItems, config.shipping);
                    return actions.order.create(order);
                },
                onApprove: async (data, actions) => {
                    await actions.order.capture();
                    dispatch(clearBasket(""));
                    setPaid(true);
                },
                onError: (err) => {
                    //   setError(err),
                    console.error(err);
                },
            })
            .render(paypalRef.current);
    }, [basketItems, dispatch]);

    // If the payment has been made
    if (paid) {
        navigate("/checkout-complete");
    }

    // If any error occurs
    if (error) {
        return <div>Error Occurred in processing payment.! Please try again.</div>;
    }

    // Default Render
    return (
        <>
            <div>
                <div style={{textAlign: "center", marginTop: 15}} ref={paypalRef} />
            </div>
        </>
    );
}

const mapStateToProps = state => {
    return {
        basketItems: state.basket.basketProducts
    };
};

export default connect(mapStateToProps)(ReactPayPal);
