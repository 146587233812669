import {createSlice} from "@reduxjs/toolkit";

const initialState = {
    basketProducts: [],
};

export const BasketSlice = createSlice({
    name: "basket",
    initialState: initialState,
    reducers: {
        addToBasket: (state, action) => {
            const {productName, qty, fabricName, productPrice} = action.payload;
            if (state.basketProducts.filter((product) => product.ProductName === productName && product.ProductFabricName === fabricName).length === 0)
            {
                state.basketProducts = state.basketProducts.concat({
                    ProductName: productName,
                    ProductImage: null,
                    ProductPrice: productPrice,
                    ProductQty: qty,
                    ProductFabricName: fabricName,
                    LinePrice: qty * productPrice,
                });
            } else {
                const product = state.basketProducts.find( product => product.ProductName === productName && product.ProductFabricName === fabricName);
                product.ProductQty += qty;
            }
        },
        removeFromBasket: (state, action) => {
            const {productName, fabricName, qty} = action.payload;
            const product = state.basketProducts.find(product => product.ProductName === productName && product.ProductFabricName === fabricName);
            if(!product) {
                return state;
            }
            if (product.ProductQty === qty) {
                return {
                    ...state,
                    basketProducts: state.basketProducts.filter((product) => product.ProductName !== productName || product.ProductFabricName !== fabricName),
                }
            }
            const remainingProducts = state.basketProducts.filter((product) => product.ProductName !== productName || product.ProductFabricName !== fabricName);
            const changedProduct = {
                ...product,
                ProductQty: product.ProductQty - qty,
            }
            remainingProducts.splice(state.basketProducts.indexOf(product), 0, changedProduct);
            return {
                ...state,
                basketProducts: remainingProducts,
            }
        },
        clearBasket: (state, action) => {
            state.basketProducts = [];
        }
    }
});

export const {addToBasket, removeFromBasket, clearBasket} = BasketSlice.actions;
