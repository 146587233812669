export default function CheckoutComplete() {
    return (<>
        <h2>Thank you</h2>
        <p>
            Your payment was successful and your order has now been sent to PuriFi reusables.
            You will be contacted when your order has been shipped with an estimated delivery date.
            Thank you for supporting our local business!
        </p>
    </>);
}
