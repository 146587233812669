export default function Fabric({fabricImage, fabricName, fabricInfo}) {
    return (
      <div style={{display: "flex", flexDirection: "row"}}>
          <img alt={fabricName} src={fabricImage} style={{flex: 1, maxWidth: 150}}/>
          <div style={{flex: 1, flexDirection: "column", margin: 10}}>
            <h3>{fabricName}</h3>
            <p>{fabricInfo}</p>
          </div>
      </div>
    );
}
