import {Button, FormControl, InputLabel, MenuItem, Select} from "@mui/material";
import {useState} from "react";
import {connect} from "react-redux";

export function Product({productImage, productName, productDescription, allowFabricChoice, onAddToBasket, fabrics, productPrice}) {

    const [fabricChoice, setFabricChoice] = useState(null);

    const chooseFabric = (name) => {
        const chosenFabric = fabrics.filter(item => item.name === name)[0];
        setFabricChoice(chosenFabric);
    }

    const [qty, setQty] = useState(1);
    const addToBasket = () => {
        onAddToBasket(productName, qty, fabricChoice.name);
    }

    return (
      <div className="product" style={{display: "flex", flexDirection: "row", flex: 1, borderTop: "2px solid #55b4d2", alignItems: "stretch", justifyContent: "center", flexWrap: "wrap"}}>

          <div className="productImage" style={{flex: 1, flexGrow: 2, textAlign: "center"}}>
              <img src={productImage} alt={productName} style={{width: "100%", minWidth: 300, margin: "0 auto", marginTop: "1em", marginBottom: "1em"}}/>
          </div>
          <div className="productInfo" style={{display: "flex", flex: 2, flexDirection: "column", flexGrow: 4, minWidth: 300, margin: 10}}>
              <h3>{productName}</h3>
              <p style={{flex: 3, flexGrow: 4}}>
                  {productDescription}
              </p>
              <p style={{textAlign: "right", fontSize: "1em"}}>Only <b style={{fontSize: "2em"}}>{displayInGBP(productPrice)}</b></p>
              <div style={{display: "flex", flexDirection: "row", flex: 1, justifyContent: "flex-end", flexWrap: "wrap"}}>
                  <div style={{display: "flex", flexShrink: 2, flexWrap: "wrap", alignItems: "center", justifyContent: "center", minWidth: 280}}>
                      {allowFabricChoice &&
                      <FormControl sx={{m: 1, minWidth: 120}}>
                          <InputLabel id="fabric-label">Fabric</InputLabel>
                          <Select id="fabric-label" value={fabricChoice?.name ?? ""} onChange={event => chooseFabric(event.target.value)} label="Fabric">
                              {fabrics.map((fabric) =>
                                  <MenuItem key={fabric.name} value={fabric.name}>{fabric.name}</MenuItem>
                              )}
                          </Select>
                      </FormControl>
                      }
                      <FormControl sx={{m: 1, minWidth: 120}}>
                          <InputLabel id="qty-label">Quantity</InputLabel>
                          <Select id="qty-label" value={qty} onChange={event => setQty(event.target.value)} label="Quantity">
                              <MenuItem value={1}>1</MenuItem>
                              <MenuItem value={2}>2</MenuItem>
                              <MenuItem value={3}>3</MenuItem>
                              <MenuItem value={4}>4</MenuItem>
                              <MenuItem value={5}>5</MenuItem>
                          </Select>
                      </FormControl>
                      <FormControl sx={{m: 1, minWidth: 120}}>
                          <Button variant="contained" disabled={fabricChoice === null && allowFabricChoice} onClick={(event) => addToBasket()} style={{height: "56px", backgroundColor: "#55b4d2"}}>Add to basket</Button>
                      </FormControl>
                  </div>
              </div>
              {
                  fabricChoice && fabricChoice.additionalInfo && fabricChoice.additionalInfo.length > 0 && (
                      <div style={{display: "flex", flex: 1, marginBottom: 10, justifyContent: "center"}}>
                          <p style={{fontSize: "0.8em"}}>{fabricChoice.additionalInfo}</p>
                      </div>
                  )
              }
          </div>
      </div>
    );
}

function displayInGBP(value) {
    return "£" + value.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");
}

export default connect()(Product);
