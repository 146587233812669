import {Table, TableBody, TableCell, tableCellClasses, TableFooter, TableHead, TableRow} from "@mui/material";
import {BsFillTrashFill} from "react-icons/bs";
import {styled} from "@mui/material/styles";
import MediaQuery from "react-responsive";
import config from "../config/config";

const StyledTableCell = styled(TableCell)(({theme}) => ({
    [`&.${tableCellClasses.head}`]: {
        backgroundColor: "#555",
        color: theme.palette.common.white
    },
    [`&.${tableCellClasses.body}`]: {
        fontSize: 14
    },
    [`&.${tableCellClasses.footer}`]: {
        backgroundColor: "#555",
        color: theme.palette.common.white,
        fontSize: 14,
        fontWeight: "bold"
    }
}));

const StyledTableRow = styled(TableRow)(({theme}) => ({
    "&:nth-of-type(odd)": {
        backgroundColor: theme.palette.action.hover
    },
    // hide last border
    "&:last-child td, &:last-child th": {
        border: 0
    }
}));

function displayInGBP(value) {
    return "£" + value.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");
}

function calculateTotal(products, shipping) {
    var total = products?.length > 0 ? shipping : 0;
    products?.forEach((item) => {
        total += item.LinePrice;
    });
    return displayInGBP(total);
}

export default function MyTable({items, deleteFromBasket}) {
    return (<>
        <MediaQuery query="(min-device-width: 700px)">
            <FullTable items={items} deleteFromBasket={deleteFromBasket}/>
        </MediaQuery>
        <MediaQuery query="(max-device-width: 700px)">
            <ResponsiveTable items={items} deleteFromBasket={deleteFromBasket}/>
        </MediaQuery>

    </>);
}

function FullTable({items, deleteFromBasket}) {
    return (<Table sx={{minWidth: 700}}>
        <TableHead>
            <TableRow>
                <StyledTableCell>
                    Item
                </StyledTableCell>
                <StyledTableCell>
                    Fabric
                </StyledTableCell>
                <StyledTableCell>
                    Item Price
                </StyledTableCell>
                <StyledTableCell>
                    Quantity
                </StyledTableCell>
                <StyledTableCell>
                    Total Price
                </StyledTableCell>
                <StyledTableCell>
                </StyledTableCell>
            </TableRow>
        </TableHead>
        <TableBody>
            {items?.map((basketItem) => {
                return (<StyledTableRow key={basketItem.ProductName}>
                    <StyledTableCell>
                        {basketItem.ProductName}
                    </StyledTableCell>
                    <StyledTableCell>
                        {basketItem.ProductFabricName}
                    </StyledTableCell>
                    <StyledTableCell>
                        {displayInGBP(basketItem.ProductPrice)}
                    </StyledTableCell>
                    <StyledTableCell>
                        {basketItem.ProductQty}
                    </StyledTableCell>
                    <StyledTableCell>
                        {displayInGBP(basketItem.LinePrice)}
                    </StyledTableCell>
                    <StyledTableCell>
                        <BsFillTrashFill color="#555" onClick={() => deleteFromBasket(basketItem.ProductName, basketItem.ProductFabricName, basketItem.ProductQty)}/>
                    </StyledTableCell>
                </StyledTableRow>);
            })}
            {items?.length > 0 &&
                <StyledTableRow>
                    <StyledTableCell>Shipping</StyledTableCell>
                    <StyledTableCell/>
                    <StyledTableCell/>
                    <StyledTableCell/>
                    <StyledTableCell>{displayInGBP(config.shipping)}</StyledTableCell>
                    <StyledTableCell/>
                </StyledTableRow>
            }
        </TableBody>
        <TableFooter>
            <StyledTableRow>
                <StyledTableCell>Total</StyledTableCell>
                <StyledTableCell/>
                <StyledTableCell/>
                <StyledTableCell/>
                <StyledTableCell>{calculateTotal(items, config.shipping)}</StyledTableCell>
                <StyledTableCell/>
            </StyledTableRow>
        </TableFooter>
    </Table>);
}

function ResponsiveTable({items, deleteFromBasket}) {
    return (
        <Table>
            <TableHead>
                <TableRow>
                    <StyledTableCell>

                    </StyledTableCell>
                    <StyledTableCell>
                        Item
                    </StyledTableCell>
                    <StyledTableCell>
                        Total Price
                    </StyledTableCell>
                </TableRow>
            </TableHead>
            <TableBody>
                {items?.map((basketItem) => {
                    return (<TableRow key={basketItem.ProductName}>
                        <StyledTableCell>
                            <BsFillTrashFill color="#555" onClick={() => deleteFromBasket(basketItem.ProductName, basketItem.ProductFabricName, basketItem.ProductQty)}/>
                        </StyledTableCell>
                        <StyledTableCell>
                            {basketItem.ProductName} <br/>
                            {basketItem.ProductFabricName} <br/>
                            {basketItem.ProductQty} @ {displayInGBP(basketItem.ProductPrice)}
                        </StyledTableCell>
                        <StyledTableCell>
                            {displayInGBP(basketItem.LinePrice)}
                        </StyledTableCell>
                    </TableRow>);
                })}
                {items?.length > 0 &&
                    <TableRow>
                        <StyledTableCell/>
                        <StyledTableCell>Shipping</StyledTableCell>
                        <StyledTableCell>{displayInGBP(config.shipping)}</StyledTableCell>
                    </TableRow>
                }
            </TableBody>
            <TableFooter>
                <StyledTableRow>
                    <StyledTableCell />
                    <StyledTableCell>Total</StyledTableCell>
                    <StyledTableCell>{calculateTotal(items, config.shipping)}</StyledTableCell>
                </StyledTableRow>
            </TableFooter>
        </Table>
    );
}
