export function ExampleTestimonialA() {
    return (
        <Testimonial imageUrl={null}
                     quote="I think these are great"
                     name="Some guy"
                     position="Some guy" />
    )
}

export function ExampleTestimonialB() {
    return (
        <Testimonial imageUrl={null}
                     quote="The quality of the PuriFi products is fantastic, I will truly be able to reuse these time and time again!"
                     name="Mark Pardington"
                     position="The Husband" />
    )
}

export function ExampleTestimonialD() {
    return (
        <Testimonial imageUrl="https://wallup.net/wp-content/uploads/2016/05/13/338869-old_people-men-wrinkled_face-monochrome-portrait.jpg"
                         quote="The quality of the PuriFi products is fantastic, I will truly be able to reuse these time and time again!"
                         name="Mark Pardington"
                         position="The Husband" />
    )
}

export function ExampleTestimonialC() {
    return (
        <Testimonial imageUrl="https://thumbs.dreamstime.com/b/funny-guy-showing-his-thumbs-up-27550766.jpg"
                     quote="I think these are great"
                     name="Some guy"
                     position="Some guy" />
    )
}

export default function Testimonial({imageUrl, quote, name, position}) {
    if (imageUrl) {
        return FullTestimonial({imageUrl, quote, name, position});
    } else {
        return ImagelessTestimonial({quote, name, position});
    }
}

function FullTestimonial({imageUrl, quote, name, position}) {
    return (
        <>
            <div className="testimonial" style={{margin: 10, maxWidth: 1140, flexDirection: "row", flex: 2, display: "flex", gap: 10, flexWrap: "wrap"}}>
                <div className="testimonial-image" style={{flex: 1, textAlign: "center"}}>
                    <img style={{maxWidth: "100%", maxHeight: 360, minWidth: 250}} src={imageUrl} alt=""/>
                </div>
                <div className="testimonial-content" style={{flexDirection: "column", flex: 1, minWidth: 250}}>
                    <blockquote style={{fontSize: "1.5em"}}>
                        {quote}
                    </blockquote>
                    <div style={{textAlign: "center"}}>
                        <b>{name}</b><br/>
                        <span style={{color: "#555"}}>{position}</span>
                    </div>
                </div>
            </div>
        </>
    )
}

function ImagelessTestimonial({quote, name, position}) {
    return (
        <>
            <div className="testimonial" style={{display: "flex", margin: 10, padding: 20, maxWidth: 1140, textAlign: "center", justifyContent: "center", height: "100%"}}>
                <div className="testimonial-content" style={{flexDirection: "column", flex: 1, minWidth: 250, maxWidth: 1040}}>
                    <blockquote style={{fontSize: "1.5em"}}>
                        {quote}
                    </blockquote>
                    <div style={{textAlign: "center"}}>
                        <b>{name}</b><br/>
                        <span style={{color: "#555"}}>{position}</span>
                    </div>
                </div>
            </div>
        </>
    )
}

