import {Grid} from "@mui/material";
import Fabric from './Fabric';
import {connect} from "react-redux";

export function FabricList({fabrics}) {
    return (
        <>
            <h2>Our fabrics</h2>
            <p>
                All items in the 'packed lunch' range are lined with fully washable, 100% recycled, eco friendly PUL fabric, uniquely certified for food storage.
                They are fastened with velcro making them ideal for both children and adults, and include name tapes for personalisation.</p>
            <p>
                Our entire range of colourful cotton and polycotton fabrics is regularly refreshed.
            </p>
            <Grid container spacing={2} columns={{xs: 4, sm: 8, md: 12}}>
                {fabrics && fabrics.map((member) => {
                   return <Grid item xs={4} key={member.name}>
                       <Fabric fabricImage={member.image} fabricName={member.name} fabricInfo={member.info} />
                   </Grid>
                })}
            </Grid>
        </>
    );
}

const mapStateToProps = state => {
    return {
        fabrics: state.products.fabrics
    };
}

export default connect(mapStateToProps)(FabricList);
