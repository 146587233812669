import Carousel from "nuka-carousel";
import Testimonial from "./Testimonial";

export default function Testimonials() {
    return (
      <Carousel>
          <Testimonial imageUrl={null} name="Rach Winsor" position="Happy Customer" quote="When you have talented friends, so you invest in their goods. I've not used single use sandwich bags in ages, moved to food containers. But this adds more flexibility. We all love them!" />
          <Testimonial imageUrl={null} name="Jessica Ronan" position="Happy Customer" quote="Ordered these lovely reusable snack pouches for my son, they are a great size and we love the fabric print! Very reasonably priced and ideal for parents of allergy kids to transport 'safe' foods in :)" />
          <Testimonial imageUrl={null} name="Helen Digger" position="Happy Customer" quote="I absolutely love my masks so I had to try the reusable makeup remover pads! I keep them in my bag for when I go swimming straight from work and micellar water is just the perfect addition. They are so soft and wash beautifully 🥰" />
      </Carousel>
    );
}
