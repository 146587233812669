import {connect, useDispatch} from "react-redux";
import {removeFromBasket} from "../store/slices/BasketSlice";
import ReactPayPal from "../components/ReactPaypal";
import MyTable from "../components/Table";


export function Basket(props) {

    const dispatch = useDispatch();

    const deleteFromBasket = (productName, fabricName, qty) => {
        dispatch(removeFromBasket({productName, fabricName, qty}));
    }

    return (
      <>
        <h2>Your Basket</h2>
          <MyTable items={props.products} deleteFromBasket={deleteFromBasket} />
          <p>All products are made to order and will be dispatched as soon as possible.</p>
          <ReactPayPal />
      </>
    );
}

const mapStateToProps = state => {
    return {
        products: state.basket.basketProducts
    };
}

export default connect(mapStateToProps)(Basket);
