import About from "../components/About";
import Testimonials from "../components/Testimonials";


export default function YourComments() {
    return (
        <>
            <About/>
            <h2> Your Comments </h2>
            <Testimonials />
        </>);
}
