import {connect, useDispatch} from "react-redux";
import {addToBasket} from "../store/slices/BasketSlice";
import Product from "./Product";

export function ProductList({products}) {

    const dispatch = useDispatch();

    const sendToBasket = (productName, qty, fabric) => {
        dispatch(addToBasket({
            productName: productName,
            qty: qty,
            fabricName: fabric,
            productPrice: products.find((product) => product.productName === productName).productPrice,
        }));
    }

    return (
        <>
            <h2>Our products</h2>
            <div style={{flexDirection: "column"}}>
                {products && products.map((product) =>
                    <Product key={product.productName} productImage={product.productImage} productName={product.productName} productDescription={product.productDescription} allowFabricChoice={product.allowFabricChoice} onAddToBasket={sendToBasket} fabrics={product.fabrics} productPrice={product.productPrice}/>
                )}
            </div>
        </>
    );
}

const mapStateToProps = state => {
    return {
        products: state.products.products,
        hasLoaded: state.products.hasLoaded
    };
}

export default connect(mapStateToProps)(ProductList);
