import "./App.css";
import {useEffect} from "react";
import {toast, ToastContainer} from "react-toastify";
import GlobalFonts from "./assets/fonts/fonts";
import Header from "./components/Header";
import {Route, Routes, BrowserRouter} from 'react-router-dom';
import Home from "./pages/Home";
import Contact from "./pages/Contact";
import Banner from "./assets/banner.jpg";
import Basket from "./pages/Basket";
import {Provider} from 'react-redux';
import YourComments from "./pages/YourComments";
import {Store} from "./store/Store";
import CheckoutComplete from "./pages/CheckoutComplete";
import 'react-toastify/dist/ReactToastify.css';

function App() {

    useEffect( () => { toast("Now donating to the Ukraine Crisis appeal for every sale") }, [] );

    return (
        <Provider store={Store}>
            <BrowserRouter>
                <div className="App">
                    <GlobalFonts/>
                    <div className="App-spacer">
                        <Header />
                    </div>
                    <header className="App-header">
                        <Header />
                    </header>
                    <ToastContainer />
                    <img src={Banner} width="100%" alt="PuriFi Reusables"/>
                    <div className="App-content">
                        <Routes>
                            <Route path="/" element={<Home />} />
                            <Route path="/home" element={<Home />} />
                            <Route path="/basket" element={<Basket />} />
                            <Route path="/comments" element={<YourComments />} />
                            <Route path="/contact" element={<Contact />} />
                            <Route path="/checkout-complete" element={<CheckoutComplete />} />
                        </Routes>
                    </div>
                </div>
            </BrowserRouter>
        </Provider>
    );
}

export default App;
