import {useEffect} from "react";
import {connect} from "react-redux";
import About from "../components/About";
import FabricList from "../components/FabricList";
import ProductList from "../components/ProductList";
import {getProductsFromApi} from "../store/slices/ProductInfoSlice";

export function Home({hasLoaded, dispatch}) {

    useEffect(() => {
        if(!hasLoaded){
            dispatch(getProductsFromApi());
        }
    }, [hasLoaded, dispatch])

    return (
        <>
            <About/>
            <br/>
            <FabricList />
            <br/>
            <ProductList />
        </>
    );
}
const mapStateToProps = state => {
    return {
        hasLoaded: state.products.hasLoaded
    };
}

export default connect(mapStateToProps)(Home);
