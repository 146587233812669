import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";
import {GetProductInfo} from "../../api/PsuedoAPI";

const initialState = {
    products: [],
    fabrics: [],
    hasLoaded: false
}

export const getProductsFromApi = createAsyncThunk(
    'product/getProductsFromApi',
    async (thunkAPI) => {
        const response = await GetProductInfo();
        return response.data
    }
);

export const ProductInfoSlice = createSlice({
    name: "product",
    initialState: initialState,
    reducers: {
    },
    extraReducers: (builder) => {
        builder.addCase(getProductsFromApi.fulfilled, (state, action) => {
            state.products = action.payload.products;
            state.fabrics = action.payload.fabrics;
            state.hasLoaded = true;
        });
    }
});
