import { createGlobalStyle } from 'styled-components';

import dense from './dense.woff';
import dense2 from './dense.woff2';

export default createGlobalStyle`
    @font-face {
        font-family: 'dense';
        src: local('dense'), url(${dense}) format('woff'), url(${dense2}) format('woff2');
    }
`;