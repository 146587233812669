export default function convertBasketItemsToPaypalPurchaseUnits(basketItems, shipping) {

    var items = basketItems.map((item) => convertBasketItemToPaypalItem(item));
    items.push(convertBasketItemToPaypalItem({
        ProductName: "shipping",
        ProductFabricName: "",
        ProductQty: 1,
        ProductPrice: shipping
    }));

    return {
        purchase_units: [
            {
                amount: getAmountFromItems(items),
                items: items
            }
        ]
    }

}

export function convertBasketItemToPaypalItem(basketItem) {

    return {
        name: basketItem.ProductName + " - " + basketItem.ProductFabricName,
        description: basketItem.ProductName + " in " + basketItem.ProductFabricName + " fabric",
        unit_amount: {
            currency_code: "GBP",
            value: basketItem.ProductPrice
        },
        quantity: basketItem.ProductQty
    }

}

function getAmountFromItem(item) {
    return (item.quantity ?? 1) * (item.unit_amount.value ?? 0);
}

export function getAmountFromItems(items) {

    const totalValue = items.reduce((a,c) => {
        return getAmountFromItem(a) + getAmountFromItem(c);
    });

    return {
        currency_code: "GBP",
        value: totalValue,
        breakdown: {
            item_total: {
                currency_code: "GBP",
                value: totalValue
            }
        }
    }

}
