export default function About() {
    return (
        <>
            <h2>Welcome to PuriFi Reusables</h2>
            <p>
                We are a small family business, created during the Covid-19 pandemic when we started to sew and sell washable face masks as a way of helping our friends and local rural community to stay safe and protected, whilst also reducing the waste of throw-away masks. Since then, we've extended our range of reusable, hand-crafted products to continue the fight against plastic and paper waste, and to promote eco-friendly living. All products are made to order and will be dispatched as soon as possible.
            </p>
        </>
    );
}
