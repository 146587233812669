import MediaQuery from "react-responsive";
import {Link} from "react-router-dom";
import Logo from "./Logo";
import {BsFillCartFill} from "react-icons/bs";
import {connect} from "react-redux";
import {Badge, createTheme, ThemeProvider} from "@mui/material";

function Contact() {
    return <div style={{margin: 10, marginRight: 20, textAlign: "center"}}><Link to="/contact" style={{color: "#555", textDecoration: "none"}}>Contact</Link></div>;
}

function Comments() {
    return <div style={{margin: 10, marginRight: 20, textAlign: "center"}}><Link to="/comments" style={{color: "#555", textDecoration: "none"}}>Your Comments</Link></div>;
}

function Home() {
    return <div style={{margin: 10, marginRight: 20, textAlign: "center"}}><Link to="/" style={{color: "#555", textDecoration: "none"}}>Home</Link></div>;
}

function Basket({items}) {
    const theme = createTheme({
        palette: {
            primary: {
                main: "#55b4d2"
            }
        }
    })
    return (<div style={{marginTop: 16, margin: 10}}><Link to="/basket">
        {items && items.length > 0 && <ThemeProvider theme={theme}><Badge badgeContent={items.length} color="primary"><BsFillCartFill color="#555" style={{fontSize: "0.9em"}} /></Badge></ThemeProvider>}
        {(!items || items.length === 0) && <BsFillCartFill color="#555" style={{fontSize: "0.9em"}} />}
    </Link></div>);
}

export function Header({basketItems}) {
    return (
        <>
         <div className="App-header-menu" style={{display: "flex", flexDirection: "row", width: "100%"}}>
             <Logo style={{flex: 1}}/>
             <div style={{display: "flex", flex: 5, flexGrow: 10, flexShrink: 2, justifySelf: "flex-end", flexDirection: "row", justifyContent: "flex-end", alignItems: "center"}}>
                 <MediaQuery query="(min-device-width: 520px)">
                     <Home />
                 </MediaQuery>
                 <Comments />
                 <Contact />
                 <Basket items={basketItems}/>
             </div>
         </div>
        </>
    )
}

const mapStateToProps = state => {
    return {
        basketItems: state.basket.basketProducts
    };
}

export default connect(mapStateToProps)(Header)
