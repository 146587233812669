import About from "../components/About";

export default function Contact() {
    return (
        <>
            <About />
            <h2>Contact us</h2>
            <p>
                To contact us, you can reach us using the below information:
            </p>
            <table>
                <tbody>
                <tr>
                    <td>
                        Email:
                    </td>
                    <td>
                        <a href="mailto:info@purifi-reusables.co.uk">info@purifi-reusables.co.uk</a>
                    </td>
                </tr>
                <tr>
                    <td>
                        Facebook:
                    </td>
                    <td>
                        <a href="https://www.facebook.com/PuriFiReusables" target="_blank" rel="noreferrer">https://www.facebook.com/PuriFiReusables</a>
                    </td>
                </tr>
                </tbody>
            </table>
        </>
    )
}
